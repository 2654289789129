import React from "react";
import { NotFound, Title, Subtitle, StyledButton } from "./styles";
const browser = typeof window !== "undefined" && window;

const NotFoundPage = ({ pageContext }) => {
  return (
    browser && (
      <NotFound
        notificationBar={
          pageContext.notificationBar
            ? pageContext.notificationBar[0].enabled
            : false
        }
      >
        <Title>404</Title>
        <Subtitle>This page is missing</Subtitle>
        <StyledButton to="/collections/all">Shop all products</StyledButton>
      </NotFound>
    )
  );
};

export default NotFoundPage;
